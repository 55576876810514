import ruLocale from 'element-ui/lib/locale/lang/ru-RU'

export const messages = {
    global: {
        ok: 'Ok',
        cancel: 'Отмена',
        save: 'Сохранить',
        send: 'Отправить',
        add: 'Добавить',
        edit: 'Редактировать',
        delete: 'Удалить',
        new: 'Новый',
        search: 'Искать...',
        unknown_server_error: 'Неизвестная ошибка сервера',
        login_time_out: 'Сессия истекла, необходимо войти в систему снова.',
        import: 'Импорт',
        form: {
            rules: {
                required: 'Поле "{fieldName}" обезательно',
                email: 'Пожалуйста введите корректный email',
                min: 'Длинна поля "{fieldName}" должна быть больше {attribute} символов',
                max: 'Длинна поля "{fieldName}" должна быть меньше {attribute} символов',
                empty: 'Поле не должно быть пустым',
                password_repeat: {
                    different: 'Пароли не совпадают',
                }
            }
        },
    },
    auth: {
        token_expired_alert_title: 'Сессия истекла!',
        token_expired_alert_text: 'Пожалуйска зайдите слова.',
        verification: {
            resend_link: 'Отправить email о верификации еще раз',
            resend_title: 'Отправка email о верификации',
            resend_button: 'Отправить',
            failed: 'Ссылка не действительная.',
        },
        resend_verification_link: 'Отправить email о верификации еще раз',
        login: {
            title: 'Войти',
            submit_button: 'Войти',
            email_label: 'Email',
            password_label: 'Пароль',
        },
        logout: {
            title: 'Выйти',
        },
        register: {
            title: 'Зарегестрироваться',
            name_label: 'Имя',
            email_label: 'Email',
            password_label: 'Пароль',
            password_confirmation_label: 'Повторите пароль',
            old_password_label: 'Старый пароль',
            submit_button: 'Отправить',
            success: 'Спасибо за регистрацию.'
        },
        logout_confirm: {
            title: 'Подтвердите выход',
            text: 'Вы будете разлогинены',
            button_ok: 'Ok',
            button_cancel: 'Отмена',
        }
    },
    setting: {
        profile: {
            name: 'Имя',
            email: 'Email',
        }
    },
    pharm: {
        new_pharm: 'Новая аптека',
        edit_pharm: 'Редактирование аптеки',
        required: {
            name: 'Адрес обязателен для заполнения',
            code: 'Код обязателен для заполнения',
            region: 'Регион обязателен для заполнения',
        }
    },
    dutyPhone: {
        lock_unlock: "Блокировать/Разблокировать",
    },
    user: {
        add: "Добавить пользователя",
        edit: "Редактировать пользователя",
    },
    DutyPhonesIt: 'Номера назначения IT',
    DutyPhonesAho: 'Номера назначения АХО',
    DutyPhones: 'Дежурный номер',
    ListPharms: 'Список аптек',
    Logs: 'Логи',
    Message: 'Сообщение',
    Reports: 'Отчеты',
    Profile: 'Профиль',
    Users: 'Пользователи',
    Records: 'Записи',
    Setting: 'Настройки',
    Stat1: 'По критическим ситуациям за месяц',
    Stat2: 'По количеству отключения электричества',
    Stat3: 'По количеству приходов специалистов Сбербанка',
    Stat4: 'Tоп звонящих аптек',
    Stat5: 'По продолжительности времени разговора с аптекой за месяц',
    Stat6: 'По количество сброшенных звонков до соединения со специалистом',
    Stat7: 'Статистика  за месяц по группам: аптеки «Фармакопейка», аптеки «Хелми», Офис/склад, Фармакопейка-Практик',
    Stat8: 'Статистика по Дежурному',
    Stat9: 'Графика по распределению звонков',
    'Show ListPharm': 'Просмотр аптеки',
    'Show Log': 'Просмотр лога',
    ...ruLocale
}
