import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/reports',
        name: 'Reports',
        component: page('ReportList'),
        iconCls: 'el-icon-data-analysis',
        class: 'float-right',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'report'
        },
        order: 509,
        children: [
            {
                path: 'stat',
                component: page('ReportList'),
                name: 'Stat1',
            },
            {
                path: 'stat2',
                component: page('ReportList'),
                name: 'Stat2',
            },
            {
                path: 'stat3',
                component: page('ReportList'),
                name: 'Stat3',
            },
            {
                path: 'stat4',
                component: page('ReportList'),
                name: 'Stat4',
            },
            {
                path: 'stat5',
                component: page('ReportList'),
                name: 'Stat5',
            },
            {
                path: 'stat6',
                component: page('ReportList'),
                name: 'Stat6',
            },
            {
                path: 'stat7',
                component: page('ReportList'),
                name: 'Stat7',
            },
            {
                path: 'stat8',
                component: page('ReportList'),
                name: 'Stat8',
            },
            {
                path: 'stat9',
                component: page('ReportList'),
                name: 'Stat9',
            },
        ]
    },
    {
        path: '/reports/:id',
        name: 'Show Report',
        component: page('ReportView'),
        hidden: true
    }
]
