import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/dutyPhonesIt',
        name: 'DutyPhonesIt',
        component: page('DutyPhoneITList'),
        iconCls: 'el-icon-goblet-full',
        beforeEnter: helper.requireAuth,
        meta: {
            access: ['IT']
        },
        order: 501
    },
    {
        path: '/dutyPhonesAho',
        name: 'DutyPhonesAho',
        component: page('DutyPhoneAHOList'),
        iconCls: 'el-icon-cold-drink',
        beforeEnter: helper.requireAuth,
        meta: {
            access: ['AHO']
        },
        order: 502
    },
    {
        path: '/dutyPhones',
        name: 'DutyPhones',
        component: page('DutyPhoneList'),
        iconCls: 'el-icon-goblet-square-full',
        beforeEnter: helper.requireAuth,
        meta: {
            access: ['duty']
        },
        order: 503
    }
]
